import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation } from "react-router-dom";

function NavScrollExample() {
  const location = useLocation();
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-[#0C1B33] h-[80px]  px-[10px] lg:px-[50px] "
        >
          <Container fluid>
            <Navbar.Brand href="#">
              <img src={require("../../assets/home/Rectangle 1.png")} alt=""  className="w-[80px] h-[51px]" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Link to="/">
                    <img
                      src={require("../../assets/home/Rectangle 1.png")}
                      alt=""
                    />
                  </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3">
                  <Nav.Link
                    href="#action1"
                    className="text-[11px] leading-[13.41px]   lg:text-[#FFFFFF] font-medium	fontClass"
                  >
                    <Link
                      to="/"
                      className={` text-[11px] leading-[13.41px] font-medium fontClass ${
                        location.pathname === "/" ? "text-[blue]" : ""
                      }`}
                    >
                      Home
                    </Link>
                    {location.pathname === "/" ? (
                      <div className="flex mt-[5px] justify-end">
                        {" "}
                        <img
                          src={require("../../assets/home/Vector 1.png")}
                          alt=""
                        />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </Nav.Link>
                  <Nav.Link
                    href="#action2"
                    className="text-[11px] leading-[13.41px]  lg:text-[#FFFFFF] font-medium	fontClass"
                  >
                    <Link
                      to="/about-us"
                      className={` text-[11px]  leading-[13.41px] font-medium fontClass ${
                        location.pathname === "/about-us" ? "text-[blue]" : ""
                      }`}
                    >
                      About
                    </Link>
                    {
                        location.pathname === '/about-us' ? <div className=" mt-[5px] flex justify-end"> <img src={require("../../assets/home/Vector 1.png")} alt="" /> </div> : ''
                    }
                  </Nav.Link>

                  <Nav.Link
                    href="#action2"
                    className="text-[11px] leading-[13.41px]  lg:text-[#FFFFFF] font-medium	fontClass"
                  >
                    <Link
                      to="/service"
                      className={` text-[11px] leading-[13.41px] mt-[5px] font-medium fontClass ${
                        location.pathname === "/service" ? "text-[blue]" : ""
                      }`}
                    >
                      Service
                    </Link>
                    {
                        location.pathname === '/service' ? <div className="mt-[5px] flex justify-end"> <img src={require("../../assets/home/Vector 1.png")} alt="" /> </div> : ''
                    }
                  </Nav.Link>
                </Nav>
                <Form className="d-flex">
                  <Link to="/contact-us">
                    <div className="text-[11px] leading-[13.41px]  lg:text-[#0093E1] font-semibold  p-[10px] border-[1.5px] border-[blue] ">
                      Contact us
                    </div>
                  </Link>
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavScrollExample;
