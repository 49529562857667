import React from "react";
import NavScrollExample from "../Navbar/NavScrollExample";
import Footer from "../Footer/Footer";
import TouchIn from "../TouchIn/TouchIn";
import { FaFireFlameCurved } from "react-icons/fa6";
const AboutUs = () => {
  return (
    <div>
      <NavScrollExample />
      <div className="max-w-[882px] uppercase mx-auto mt-[100px] text-center font-extrabold text-[40px] md:text-[70px] fontClass3 leading-[70px] text-[#0093E1]">
        Our Mission
      </div>
      <div className=" mt-[10px]  uppercase text-grey text-[16px]  w-[90%]   md:max-w-[1197px] mx-auto leading-[26px]">
        At Alpha Timberline Security LLC, our mission is to deliver superior
        security services
        <br />
        with an unwavering commitment to safety, precision, and reliability. We
        stand dedicated to protecting assets, people, and communities
        <br /> with a vigilant approach tailored to meet the diverse security
        needs of our clients across all environments.
      </div>

      <div className="w-full flex  flex-col-reverse  lg:flex-row  xl:pl-[200px] py-[100px]">
        <div className=" w-full lg:w-[50%] bg-[#0C1B33] py-[50px]">
          {/* <div className="text-white text-[35px] uppercase leading-[35px] text-start pl-[50px] fontClass3 font-extrabold max-w-[505px]  ">
            Our Services
          </div>
          <div className="text-start mt-[20px] max-w-[90%] lg:max-w-[428px] text-white text-[12px] leading-[19.42px] pl-[50px]  ">
            Briefly bullet the services we provide, with a link to the services
            page for more details. Also, add some creative links that you can
            click on that will take them to the services page.
          </div> */}

          <div className="text-white   text-[35px] uppercase leading-[35px] text-start pl-[50px] fontClass3 font-extrabold max-w-[505px]  ">
            Our Commitment
          </div>
          <div className="text-start mt-[20px] uppercase max-w-[90%] lg:max-w-[428px] text-white text-[12px] leading-[19.42px] pl-[50px]  ">
            At ATS LLC, we believe in making a positive impact not just through
            our services, but also within the community. We are proud to commit
            3% of all our earnings to support children in need. This initiative
            allows us to contribute to the well-being of our future generations,
            ensuring they receive the care and opportunities they deserve.
            Through partnerships with local and international charities, we
            ensure that every dollar contributed makes a real difference. Our
            clients are not just purchasing security—they are investing in a
            safer, more hopeful future for children around the world. Join us in
            our mission to secure not only our client's assets but also a
            brighter future for children in need.
          </div>
        </div>
        <div className=" w-full lg:w-[50%]    lg:ml-[-100px] lg:mb-[-30px]  mt-[30px] ">
          <img
            src={require("../../assets/home/Isolation_Mode.png")}
            alt=""
            className="h-full w-full"
          />
        </div>
      </div>

      <div className="w-full pb-[50px] flex flex-col-reverse justif-center    md:flex-row-reverse  items-center">
        <div className="w-full md:w-[50%]">
          <div className="   p-[10px] uppercase text-start text-[#000000] font-extrabold fontClass3 text-[35px] leading-[36px] max-w-[529px] mx-auto">
            Our story
          </div>
          <div className=" px-[20px]  max-w-[529px] mx-auto uppercase  text-start text-[#575756]">
            Founded amidst the rugged landscapes of Oregon, Alpha Timberline
            Security LLC was born from a desire to blend military precision with
            environmental awareness in the realm of security services.
            Co-founders brought together their extensive experience in military
            operations and technical management skills to form a security firm
            unlike any other.
          </div>

          <div className="mt-[20px] px-[20px] uppercase text-start text-[#000000] font-extrabold fontClass3 text-[35px] leading-[36px] max-w-[529px] mx-auto">
            Our Values
          </div>
          <div className="px-[20px] max-w-[529px]  uppercase font-bold mx-auto text-start text-[#575756]">
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              <li>Integrity</li>
              <li>Vigilance</li>
              <li>Preparedness</li>
            </ul>
          </div>

          <div className="mt-[20px] p-[10px] uppercase text-start text-[#000000] font-extrabold fontClass3 text-[35px] leading-[36px] max-w-[529px] mx-auto">
            Our Future
          </div>
          <div className=" px-[20px]  uppercase max-w-[529px] mx-auto text-start text-[#575756]">
            We envision a path of growth that is both ambitious and grounded in
            our foundational principles. Our goal is to be recognized as a
            leader in security services, known for our adaptability, integrity,
            and the unwavering quality of our work. We are actively expanding
            our expertise to serve a variety of industries and sectors, always
            prioritizing the safety and well-being of our clients and their
            interests.
          </div>
        </div>
        <div className="w-full md:w-[50%]">
          <img
            src={require("../../assets/home/sec.png")}
            alt=""
            className="w-full"
          />
        </div>
      </div>
      <TouchIn />

      <Footer />
    </div>
  );
};

export default AboutUs;
