import React from "react";
import NavScrollExample from "../../Component/Navbar/NavScrollExample";
import Footer from "../../Component/Footer/Footer";
import TouchIn from "../../Component/TouchIn/TouchIn";
import WhyUs from "../../Component/WhyUS/WhyUs";
import Slide from "./Slide";
import Hero from "./Hero";
import WhyUs2 from "../WhyUS/WhyUs2";
import SeviceSlide from "../Service/SeviceSlide";
const Home = () => {
  return (
    <div>
      <NavScrollExample />
      <Hero />

      <WhyUs2 />
      <div className="py-[100px]">
        <div className=" uppercase max-w-[523px] mx-auto fontClass3 font-black text-[40px] sm:text-[56px] leading-[40px] sm:leading-[57px] text-[#0C1B33]">
        Commendations
        </div>

        <div className="mt-[100px] py-[50px] bgclass">
          {/* <div className="  relative flex justify-center items-center mb-[-70px] z-10">
            <img src={require("../../assets/home/Ellipse 17 (1).png")} alt="" />
          </div> */}
          <div className="relative">
            <SeviceSlide />
          </div>
        </div>
      </div>

      {/* <Slide /> */}
      <TouchIn />
      <Footer />
    </div>
  );
};

export default Home;
