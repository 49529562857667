import React from "react";
import Slider from "react-slick";

function AutoPlay() {
  const data = [
    {
      name: "Mobile Patrol Services",
    },

    {
      name: "Event Security",
    },
    {
      name: "Construction Site Security",
    },
    {
      name: "CCTV Monitoring and Response",
    },
    {
      name: "Executive Protection Services",
    },
    {
      name: "Access Control Systems Management",
    },
    {
      name: "Fire Watch Services",
    },
    {
      name: "Residential Security Services",
    },
    {
      name: "Corporate Security Solutions",
    },
    {
      name: "Cargo and Container Security",
    },
    {
      name: "Maritime Security for Cargo Ships",
    },
  ];
 const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 3000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

  return (
    <div className="slider-container py-[20px] ">
      <Slider {...settings}>
        {data.map((item) => {
          return (
            <div className="classPadingFOr   py-[30px] flex max-w-[350px]  text-[#0093E1] rounded-[20px]    text-[16px] leading-[20px] font-bold fontClass2 bg-white">
              {item.name}
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default AutoPlay;
