import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const items = [
    {
      name: "About us",
      link: "/about-us",
    },
    {
      name: "Services",
      link: "/service",
    },
    {
      name: "Contact us",
      link: "/contact-us",
    },
  ];
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll behavior
    });
  };
  return (
    <div>
      <div className="flex  gap-[50px]   md:gap-[0px]  flex-col md:flex-row flex-wrap justify-center  md:justify-between items-start p-[20px] lg:p-[50px]">
        <div>
          <img src={require("../../assets/home/Rectangle 1.png")} alt="" />
        </div>
        <div>
          <div className="fontClass2 text-[14px] leading-[20px]  text-[black] font-medium">
            Quick Links
          </div>
          <ul className="pt-[20px] text-start ">
            {items.map((item, index) => (
              <li className="pt-[10px]" key={index}>
                <Link to={item.link}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <div className=" text-start fontClass2 text-[14px] leading-[20px] text-[black] font-medium">
            Address
          </div>
          <div className="text-[14px] pt-[20px] text-start text-[#000000] leading-[20px] fontClass2 ">
            Based in Portland Oregon
          </div>
          <div className="text-[14px] pt-[20px] text-start text-[#000000] leading-[20px] fontClass2 ">
          +1(971) 770-4524
          </div>
          <div className="text-[14px] pt-[20px] text-start text-[#000000] leading-[20px] fontClass2 ">
          +1(971) 226-7387
          </div>
          <div className="text-[14px] pt-[20px] text-start text-[#000000] leading-[20px] fontClass2 ">
          info@alphatimberlinesecurity.com
          </div>
       
        </div>
        <div>
          <img src={require("../../assets/home/social.png")} alt="" />
        </div>
      </div>
      <div className="flex justify-between items-center  p-[50px] ">
        <img src={require("../../assets/home/© 2024 — Copyright.png")} alt="" />
        <img
          src={require("../../assets/home/top.png")}
          alt=""
          onClick={scrollToTop}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default Footer;
