import React, { useState } from "react";
import Swal from "sweetalert2";

const TouchIn = () => {
  const [formData, setFormData] = useState({
    name: "",
    UserName: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://localhost:5000/api/sendData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        Swal.fire({
          title: "Information",
          text: "Your Infromation Submit Successfully",
          icon: "success"
        });
        // Data sent successfully
        console.log("Data sent successfully");
        // You can optionally reset the form here
        setFormData({
          name: "",
          UserName: "",
          subject: "",
          message: "",
        });
      } else {
        // Handle error
        console.error("Failed to send data");
      }
    } catch (error) {
      console.error("Internal server error", error);
    }
  };

  return (
    <div className="w-full flex flex-col justify-center   lg:flex-row ">
      <div className=" w-full lg:w-[50%]">
        <img
          src={require("../../assets/home/Component 7.png")}
          alt="w-full"
          className="w-full"
        />
      </div>
      <div className=" w-full lg:w-[50%] bg-[#0C1B33] px-[20px] py-[50px]  ">
        <div className="fontClass3 font-bold text-[54px] leading-[56px] text-white ">
          Get in <span className="text-[blue]">Touch</span>{" "}
        </div>
        {/* <div className="mt-[20px]  text-[12px] text-white leading-[24px] text-start mx-auto  max-w-[545px] fontClass" >
          Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo
          molestie vel, ornare non id blandit netus.
        </div> */}
        <div className="py-[10px] mt-[20px]   pl-[10px] border-[1px] border-white mx-auto  max-w-[545px] flex justify-center items-center ">
          <input
            type="text"
            placeholder="Name*"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className=" w-full text-white bg-[#0C1B33]"
          />
        </div>

        <div className="py-[10px] mt-[20px]  pl-[10px] border-[1px] border-white  mx-auto max-w-[545px] flex justify-center items-center ">
          <input
            type="text"
            placeholder="Email"
            name="UserName"
            value={formData.UserName}
            onChange={handleChange}
            className=" w-full text-white bg-[#0C1B33]"
          />
        </div>
        <div className="py-[10px] mt-[20px] pl-[10px] border-[1px] border-white mx-auto max-w-[545px] flex justify-center items-center ">
          <input
            type="text"
            placeholder="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className=" w-full text-white bg-[#0C1B33]"
          />
        </div>
        <div className="py-[10px] mt-[20px] pl-[10px] border-[1px] border-white mx-auto max-w-[545px] flex justify-center items-center ">
          <textarea
            type="text"
            placeholder="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className=" w-full text-white h-[148px] bg-[#0C1B33]"
          />
        </div>
        <div className="mt-[50px] mx-auto max-w-[545px]  cursor-pointer  "  onClick={handleSubmit}  >
          <img src={require("../../assets/home/button.png")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default TouchIn;
