import React from "react";
import { FaFireFlameCurved } from "react-icons/fa6";
const WhyUs2 = () => {
  const data = [
    {
      name: "Dedicated to Excellence and Precision",
      text: "Every member of our team at Alpha Timberline Security LLC undergoes comprehensive training in the latest security protocols and emergency response tactics. We ensure our staff not only meet but exceed industry standards, providing unparalleled expertise. Our commitment to precision in every assignment ensures client satisfaction and trust.",
      img: require("../../assets/home/mask1.png"),

    },
    {
      name: "A Legacy of Trust and Efficiency ",
      img: require("../../assets/home/vaadin_handshake.png"),
      text: "Though Alpha Timberline Security LLC may be a newer company in the security industry, our foundation is built on decades of cumulative experience from our leadership and team members. Our unwavering commitment to operational excellence is clearly demonstrated through the enduring partnerships we have established across the region.",
    },
    {
      text: "Alpha Timberline Security LLC may have started locally, but our impact spans Across  the nation. We combine local knowledge with the capabilities of a large firm, ensuring each client benefits from our nationwide network of security professionals.",

      name: "Local Expertise with a Broad Reach",
      img: require("../../assets/home/h5.png"),
    },
    {
      text: "Alpha Timberline Security LLC stands out with a proven strategy that adapts to the complexity of any security challenge. We harness the synergy between cutting-edge technology and seasoned expertise to deliver security solutions that are both dynamic and reliable.",

      name: "Strategic Security Solutions",
      img: require("../../assets/home/gridicons_pencil.png"),
    },
    // {
    //   text: "While our company may be new, our founders and team members bring a wealth of experience to the table, having been integral to security operations at major wildfire incidents like the Jack Fire, Bedrock Fire, and others. Our leadership has been recognized for professionalism and effectiveness in high-stakes environments.",

    //   name: "Local Presence, Nationwide Impact",
    //   img: require("../../assets/home/h5.png"),
    // },
  ];
  return (
    <div className="w-full  bg-[#0C1B33] flex flex-col  py-[100px]">
      <div className=" w-full ">
        <div className="text-[88px]  pl-[20px] sm:pl-[0px]  uppercase text-start max-w-[447px] mx-auto  font-bold leading-[90px] text-white">
        <div className="flex justify-center items-center">

         <img src={require("../../assets/home/Why Us.png")} alt="" />
        </div>

          <div className="flex justify-center items-center">

          <img
            src={require("../../assets/home/Vector 5.png")}
            alt=""
            className="mt-[20px] w-[75%]"
          />
          </div>
        </div>
        {/* <div className="h-[2px] max-w-[300px] mx-auto bg-white"></div> */}
        <div className="text-[22px] text-bold  px-[20px] sm:px-[0px]  leading-[17px] mt-[50px] max-w-[547px] mx-auto fontClass text-white text-start ">
        LOCAL PRESENCE AND NATION WIDE IMPACT
        </div>
        {/* <div className="text-[12px] text-normal  px-[20px] sm:px-[0px]  leading-[17px] mt-[10px] max-w-[447px] mx-auto fontClass text-white text-start ">
          Based in Oregon, we understand the specific security needs of the West
          Coast and beyond. Our local insight combined with our nationwide reach
          allows us to serve a broad spectrum of clients with precision and
          reliability
        </div> */}
      </div>

      <div className="flex mt-[50px] flex-wrap justify-center gap-[50px] px-[20px] sm:px-[50px] w-full ">
        {data.map((item, index) => (
          <div key={index} className="     ">
            <div className="flex  p-[20px] w-full  justify-start items-start  rounded-[31px]   bg-white  mx-auto   sm:max-w-[606px] p-[10px] mt-[20px]">
              <div className=" w-[20%] sm:w-[10%]">
                <img src={item.img} alt="" className="w-[35px] h-[35px]" />
              </div>
              <div className="  w-[80%] sm:w-[90%]">
                <div className=" text-start text-[#0C1B33] uppercase   font-bold text-[14px] fontClass">
                  {item.name}
                </div>
                <div className=" text-start mt-[5px] font-normal leading-[15px] text-[#000000] uppercase    text-[11px] fontClass leading-[15px]  ">
                  {item.text}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyUs2;
