import React, { useState } from "react";
import NavScrollExample from "../Navbar/NavScrollExample";
import Footer from "../Footer/Footer";
import Swal from "sweetalert2";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    UserName: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://localhost:5000/api/sendData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        Swal.fire({
          title: "Information",
          text: "Your Infromation Submit Successfully",
          icon: "success"
        });
        // Data sent successfully
        console.log("Data sent successfully");
        // You can optionally reset the form here
        setFormData({
          name: "",
          UserName: "",
          subject: "",
          message: "",
        });
      } else {
        // Handle error
        console.error("Failed to send data");
      }
    } catch (error) {
      console.error("Internal server error", error);
    }
  };

  return (
    <div>
      <NavScrollExample />
      <div className="w-full">
        <img
          src={require("../../assets/home/coe.png")}
          alt=""
          className="w-full"
        />
      </div>

      <div className="w-full flex flex-col justify-center items-center   lg:flex-row ">
        <div className=" w-full lg:w-[50%] py-[50px]">
          <div className="font-bold text-start max-w-[360px] mx-auto text-[36px] text-[#0C1B33] leading-[46px] fontClass3">
            Let's talk
          </div>
          <div className="text-[16px] fontClass text-start max-w-[360px] mx-auto leading-[26px] text-[#575756]">
            Questions, comments, or suggestions? Simply fill in the form and
            we’ll be in touch shortly.
          </div>

          <div className="flex  mt-[30px] gap-[20px] justify-start max-w-[360px] mx-auto items-center">
            <img src={require("../../assets/home/Group 3893.png")} alt="" />
            <div className="text-[#011334] text-[18px] leading-[26px] font-bold">
              Based in Portland Oregon
            </div>
          </div>
          <div className="flex  mt-[30px] gap-[20px] justify-start max-w-[360px] mx-auto items-center">
            <img
              src={require("../../assets/home/noun_Phone_3612570 1.png")}
              alt=""
            />
            <div className="text-[#011334] text-[18px] leading-[26px] font-bold">
              +1(971) 770-4524
            </div>
          </div>
          <div className="flex   mt-[30px] gap-[20px] justify-start max-w-[360px] mx-auto items-center">
            <img
              src={require("../../assets/home/noun_Email_247564 1.png")}
              alt=""
            />
            <div className="text-[#011334] text-[18px] leading-[26px] font-bold">
              info@alphatimberlinesecurity.com
            </div>
          </div>
        </div>
        <div className=" w-full lg:w-[50%] bg-[#0C1B33] px-[20px] py-[50px]  ">
    
        {/* <div className="mt-[20px]  text-[12px] text-white leading-[24px] text-start mx-auto  max-w-[545px] fontClass" >
          Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo
          molestie vel, ornare non id blandit netus.
        </div> */}
        <div className="py-[10px] mt-[20px]   pl-[10px] border-[1px] border-white mx-auto  max-w-[545px] flex justify-center items-center ">
          <input
            type="text"
            placeholder="Name*"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className=" w-full text-white  outline-none border-none bg-[#0C1B33]"
          />
        </div>

        <div className="py-[10px] mt-[20px]  pl-[10px] border-[1px] border-white  mx-auto max-w-[545px] flex justify-center items-center ">
          <input
            type="text"
            placeholder="Email"
            name="UserName"
            value={formData.UserName}
            onChange={handleChange}
            className=" w-full text-white outline-none border-none bg-[#0C1B33]"
          />
        </div>
        <div className="py-[10px] mt-[20px] pl-[10px] border-[1px] border-white mx-auto max-w-[545px] flex justify-center items-center ">
          <input
            type="text"
            placeholder="Subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className=" w-full text-white outline-none border-none bg-[#0C1B33]"
          />
        </div>
        <div className="py-[10px] mt-[20px] pl-[10px] border-[1px] border-white mx-auto max-w-[545px] flex justify-center items-center ">
          <textarea
            type="text"
            placeholder="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className=" w-full text-white h-[148px]  outline-none border-none bg-[#0C1B33]"
          />
        </div>
        <div className="mt-[50px] mx-auto max-w-[545px]  cursor-pointer  "  onClick={handleSubmit}  >
          <img src={require("../../assets/home/button.png")} alt="" />
        </div>
      </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
