import React from "react";

const WhyUs3 = () => {
  return (
    <div className="w-full   flex flex-col  lg:flex-row  ">
      <div className=" w-full lg:w-[50%]">
        <img
          src={require("../../assets/home/Rectangle 24.png")}
          alt=""
          className="w-full h-full"
        />
      </div>
      <div className=" w-full lg:w-[50%] p-[20px] lg:p-[0px]   flex flex-col justify-center  bg-[#0C1B33] h-[full]">
        <div className=" max-w-[528px] mx-auto  text-[36px] font-bold max-w-[588px]   text-[#FFFFFF] leading-[58px] fontClass   ">
         TAILORED SECURITY SERVICES 
        </div>
        <br />
        <div className="text-[12px] uppercase text-start max-w-[528px] mx-auto leading-[19.5px] text-white fontClass">
          At Alpha Timberline Security LLC, we
          understand that each client has unique security requirements. That's
          why we prioritize a personalized approach, meticulously crafting
          security strategies that align with the specific challenges and
          objectives of every client we serve. Our elite team of certified
          security professionals is not only trained in the latest safety
          protocols but also in adaptive tactics to meet the ever-changing
          demands of your environment. Recognizing that standardized solutions
          don't fit all, we dedicate ourselves to delivering bespoke security
          services. Our commitment to customization ensures that whether you
          need comprehensive protection for your corporate space, specialized
          monitoring for your event, or a robust response plan for your
          facility, Alpha Timberline Security is the pinnacle of personalized
          security excellence.
        </div>
      </div>
    </div>
  );
};

export default WhyUs3;
